import { ChatMessage } from '@azure/openai'
import DeleteIcon from '@mui/icons-material/Delete'
import PersonIcon from '@mui/icons-material/Person'
import SendIcon from '@mui/icons-material/Send'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import { MuiMarkdown } from 'mui-markdown'
import { ChangeEvent, useRef, useState } from 'react'

import DialogMessage from './DialogMessage'
import { chatCompletion } from './api'

const Chat = () => {
  const [messages, setMessages] = useState<Array<ChatMessage>>([])
  const [userContent, setUserContent] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [showDialogMessage, setShowDialogMessage] = useState<boolean>(false)
  const chatRef = useRef<HTMLUListElement>(null)

  const sendUserMessage = async (): Promise<void> => {
    if (userContent.length === 0) {
      return
    }

    const previousMessages: Array<ChatMessage> = [...messages]
    const userMessage: ChatMessage = {
      role: 'user',
      content: userContent,
    }
    try {
      setUserContent('')
      setMessages([...previousMessages, userMessage])
      setLoading(true)
      const completionMessages = await chatCompletion([...previousMessages, userMessage])
      setMessages([...previousMessages, userMessage, ...completionMessages])
    } catch (e) {
      setShowDialogMessage(true)
      setUserContent(userMessage.content || '')
      setMessages(previousMessages)
    } finally {
      setLoading(false)
      if (chatRef.current) {
        chatRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  return (
    <>
      <DialogMessage
        open={showDialogMessage}
        onClose={() => setShowDialogMessage(false)}
        severity="error"
        message="Internal Server Error"
      />
      <Box maxWidth="sm">
        <List sx={{ pb: 20 }} ref={chatRef}>
          {messages.map((m) => (
            <ListItem key={m.role + m.content || ''}>
              <ListItemAvatar>
                {m.role === 'user' ? (
                  <Avatar>
                    <PersonIcon />
                  </Avatar>
                ) : (
                  <Avatar alt="Poyo" src="/images/poyo-icon.png" />
                )}
              </ListItemAvatar>
              <ListItemText primary={<MuiMarkdown>{m.content}</MuiMarkdown>} />
            </ListItem>
          ))}
          {loading ? (
            <ListItem>
              <ListItemAvatar>
                <Avatar alt="Poyo" src="/images/poyo-icon.png" />
              </ListItemAvatar>
              <ListItemText secondary={<MuiMarkdown>読み込み中...</MuiMarkdown>} />
            </ListItem>
          ) : null}
          {messages.length <= 1 ? null : (
            <ListItem sx={{ textAlign: 'center' }}>
              <Button
                variant="outlined"
                color="error"
                sx={{ textAlign: 'center' }}
                startIcon={<DeleteIcon />}
                onClick={() => setMessages([])}
                disabled={loading}
              >
                会話を削除する
              </Button>
            </ListItem>
          )}
        </List>
        <Paper
          component="form"
          sx={{
            position: 'fixed',
            bottom: 30,
            p: '5px 10px',
            display: 'flex',
            alignItems: 'center',
            maxWidth: 'calc(min(82%, 532px))',
            width: '100%',
          }}
          onSubmit={(e) => {
            e.preventDefault()
            sendUserMessage()
          }}
          elevation={6}
        >
          <InputBase
            autoFocus
            fullWidth
            color="primary"
            placeholder={messages.length === 0 ? '例: 何歳ですか？' : '何でも聞いてください。'}
            value={userContent}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setUserContent(e.target.value)}
          />
          <IconButton
            type="button"
            color="primary"
            onClick={sendUserMessage}
            disabled={userContent.length === 0 || loading}
            title="メッセージを送信する"
          >
            <SendIcon />
          </IconButton>
        </Paper>
      </Box>
    </>
  )
}

export default Chat
