import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'

import Chat from './Chat'
import Icon from './Icon'
import Profile from './Profile'

const App = () => {
  return (
    <>
      <Container maxWidth="sm">
        <Stack spacing={2}>
          <Icon />
          <Profile />
          <Chat />
        </Stack>
      </Container>
    </>
  )
}

export default App
