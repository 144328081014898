import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'

const Icon = () => {
  return (
    <>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
        <Avatar
          alt="Poyo"
          src="/images/poyo-icon.png"
          sx={{
            width: 'calc(max(20vh, 96px))',
            height: 'calc(max(20vh, 96px))',
          }}
        />
      </Box>
    </>
  )
}

export default Icon
