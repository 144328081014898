import Alert, { AlertColor } from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'

interface DialogMessageProps {
  open: boolean
  onClose: () => void
  severity: AlertColor
  message: string
}

const DialogMessage = (props: DialogMessageProps) => {
  return (
    <Snackbar
      open={props.open}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={props.onClose}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={props.onClose}
        severity={props.severity}
        sx={{ width: '100%' }}
      >
        {props.message}
      </Alert>
    </Snackbar>
  )
}

export default DialogMessage
