import Typography from '@mui/material/Typography'

import './Profile.css'

const Profile = () => {
  return (
    <>
      <Typography sx={{ mb: 1.5 }} variant="h5" component="div" align="center">
        森 信輔 (ぽよ)
      </Typography>
      <Typography variant="body1">
        ぽよ本人に代わって自己紹介してくれる AI を作りました。ぜひ気軽に話しかけてみてください。
      </Typography>
      <ul className="notation-list">
        <li>インターネット公開環境なので社外秘の情報は話せません。</li>
        <li>
          教えてない情報に対しては適当な回答をします。「これホント？」と気になった回答は直接本人に聞いてください。
        </li>
      </ul>
    </>
  )
}

export default Profile
